<script setup lang="ts">
import type { ITextIcon } from "~/interfaces";
import Select from "~/components/inputs/Select.vue";
import Button from "~/components/inputs/Button.vue";
import type { IValue } from "~/interfaces";
import validationJson from "~/static/locale/it/validation.json";

const {ctaText, ctaSubtext, icons} = defineProps({
    ctaText: { type: String, required: true },
    ctaSubtext: { type: String, required: false, default: '' },
    icons: { type: Array as () => ITextIcon[], required: false, default: [] },
    values: { type: Array as () => IValue[], default: () => [], required: true },
    value: { type: Number, required: false },
})

const iconComponents = computed(() => {
    return icons.map(icon => iconComponent(icon))
});

const credit = useState<number | null>("selectedCredit", () => null);

defineComponent({
  name: "Credit",
});

const emits = defineEmits(['submit'])

const showError = ref<boolean>(false)

const validate = (e: Event) => {
  e.stopPropagation()
  e.preventDefault()
  credit.value != undefined ?  emits("submit") : showError.value = true
}

</script>

<template>
  <div class="credit-form md:container mx-auto text-center px-6 sm:px-10 md:px-6">
    <div class="bg-white rounded-3xl shadow-2xl px-4 mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
      <p class="text-gray-5 font-semibold pt-4 sm:pt-10 pb-2 mb-4 px-4 text-2xl sm:px-8 lg:text-3xl">
          <slot name="title"></slot>
      </p>

      <form @submit.prevent="(e) => validate(e)">
          <div class="max-w-md mx-auto">
            <Select
                class="w-full py-2 px-6 leading-normal rounded-full h-12 text-lg focus-within:shadow-white"
                disableZeroValue
                :values="values"
                v-model="credit"
                :error="showError"
                :error-message="validationJson.required"
            >
            </Select>
            <button type="submit" class="home-submit">
              <template v-if="$slots.cta">
                <slot name="cta" />
              </template>

              <template v-else>
                <span class="block font-bold text-xl leading-5">Continua</span>
                <span class="block leading-5 text-base">esito immediato</span>
              </template>
            </button>
          </div>
      </form>

      <h2 class="mt-6 sm:mt-8 text-lg xs:text-xl text-gray-5 font-light">
          <slot name="text"></slot>
      </h2>

      <div class="flex flex-wrap items-start py-4 sm:py-4 lg:px-40">
        <template v-for="(el, index) in iconComponents" :key="index">
          <div class="w-1/6 lg:hidden" v-if="index === 0"></div>
          <div :class="`w-1/3 md:w-${index  % 2 === 0 ? '2' : '1'}/5 lg:w-1/4 text-center my-4`">
            <component :is="el.icon"></component>
            <h4 class="block text-sm md:inline-block lg:px-2 font-light mb-[2px] mt-[5px]" v-html="el.text"></h4>
          </div>

          <div class="w-1/6 lg:hidden" v-if="index ===  1" v-for="i in 2" :key="i"></div>
        </template>
      </div>
    </div>
</div>
</template>

<style scoped lang="css"></style>
