<script setup lang="ts">
import SlideUpDown from 'vue-slide-up-down'

interface Faq {
  q: String;
  a: String;
}

const { items, show } = defineProps({
  items: { type: Array as () => Faq[], required: true },
  show: { type: Number, required: true  }
});

const activeFaq: Ref<Number | null> = ref(null);

const allFaqs: Ref<Boolean> = ref(false);

const toggleFaq = (i: Number) => {
  if (activeFaq.value == i) activeFaq.value = null;
   else activeFaq.value = i;
}
</script>

<template>
  <section class="section-faq py-10 bg-gray-1 md:py-16 primary">
    <div class="sm:container px-6 mx-auto">
      <h3 class="font-semibold text-3xl text-gray-5 leading-none px-6 mb-10 text-center lg:text-4xl">
       <slot name="title" />
      </h3>
      <div class="faq-container w-full flex flex-wrap hover:text-orange cursor-pointer mb-5" :class="{ hidden: i > 3 && !allFaqs }" v-for="(faq, i) in items" :key="i">
        <div class="h-10 w-10 text-center rounded-full leading-10 mr-5 -mt-2 outline-none flex items-center justify-center" :class="{'bg-gray-2': activeFaq != i, 'bg-yellow shadow-xl': activeFaq == i}" @click="toggleFaq(i)">
          <svg xmlns="http://www.w3.org/2000/svg" class="text-white h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" :class="{'transform rotate-180': activeFaq != i}">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
          </svg>
        </div>
        <div class="w-4/5">
          <div class="faq-question mb-4 outline-none" :class="{ active: activeFaq == 1 }" @click="toggleFaq(i)">
            <span class="font-semibold text-2xl text-gray-5 leading-none">{{ faq.q }}</span>
          </div>
          <SlideUpDown class="faq-content mb-4 font-medium text-gray-5 cursor-default outline-none" :active="activeFaq == i"><span class="font-light text-lg text-gray-5" v-html="faq.a"></span></SlideUpDown>
        </div>
      </div>
      <div class="text-center" v-if="allFaqs" @click="allFaqs = false">
        <p class="inline font-semibold text-lg text-red-1 text-center cursor-pointer mt-6 uppercase border-b-4 border-red-1">
          Mostra meno
        </p>
      </div>
      <div v-else class="text-center" @click="allFaqs = true">
        <p class="inline font-semibold text-lg text-red-1 text-center cursor-pointer mt-6 uppercase border-b-4 border-red-1">
          Carica altre
        </p>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
