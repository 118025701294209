<script setup lang="ts">
  import Swiper from "swiper"

  const widget = ref(null)

  onMounted(() => {
    initializeTrustPilot()
    initializeSwiper()
  });

/**
 * Initialize Trust Pilot Script
 */
const initializeTrustPilot = () => {
  const src = "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
  let script : HTMLScriptElement | null = document.querySelector(`script[src='${src}']`);

  //Add script if is not loaded yet
  if (!script) {
    script = document.createElement("script");
    script.src = src;
    document.head.appendChild(script);
  }

  //reload widget
  if (window.Trustpilot && widget.value){
    window.Trustpilot.loadFromElement(widget.value);
  }
}

const initializeSwiper = () => {
  new Swiper("#trustpilot-sw.swiper-container", {
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
      el: "#trustpilot-sw .swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: "#trustpilot-sw .swiper-button-next",
      prevEl: "#trustpilot-sw .swiper-button-prev",
    },
  });
}
</script>

<template>
  <section class="section-trustpilot primary md:mt-0 p-6 sm:py-16 bg-white">
    <div class="container mx-auto">
      <!-- Title -->
      <div class="text-center mb-8 sm:mb-16">
        <h3 class="font-semibold text-3xl text-gray-5 leading-none md:text-4xl">
          <slot name="title"></slot>
        </h3>
      </div>
      <div ref="widget" class="trustpilot-widget"
           data-locale="it-IT"
           data-template-id="54ad5defc6454f065c28af8b"
           data-businessunit-id="5d0a61ad3bcbc70001701a39"
           data-style-height="240px"
           data-style-width="100%"
           data-theme="Organization"
           data-stars="4,5"
           data-review-languages="it"
      >
          <a href="https://it.trustpilot.com/review/www.prestitocofidis.com" target="_blank" rel="noopener">Trustpilot</a>
      </div>
    </div>
  </section>
</template>
