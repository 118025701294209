<template>
  <section class="section-logos pb-6">
    <div class="container flex flex-wrap items-center mx-auto px-8 py-10 md:py-16 lg:px-32">
      <div class="w-1/2 text-center mb-6 sm:w-1/4 sm:mb-0">
        <nuxt-img
          format="webp" class="mx-auto w-40" alt="Prestito Personale – Google Rating" title="Google Rating" src="/images/static/google_rating_stelle_gialle.png" />
      </div>
      <div class="w-1/2 text-center mb-6 sm:w-1/4 sm:mb-0">
        <nuxt-img
          format="webp" class="mx-auto w-52" alt="Prestito Personale – Best Workplaces" title="Logo Best Workplaces" src="/images/static/logo_best_workplaces.png" />
      </div>
      <div class="w-1/2 text-center mb-6 sm:w-1/4 sm:mb-0">
        <nuxt-img
          format="webp" class="mx-auto w-24 lg:w-32" alt="Prestito Personale – Cofidis Certificazione Ottima Finanziaria" title="Logo Certificazione Ottima Finanziaria" src="/images/static/ottima_finanziaria_2022.png" />
      </div>
      <div class="w-1/2 text-center mb-6 sm:w-1/4 sm:mb-0">
        <nuxt-img
          format="webp" class="mx-auto w-full" alt="Prestito Personale – TrustPilot 2022" title="TrustPilot 2022" src="/images/static/trustpilot_cofidis_2022.png" />
      </div>
    </div>
  </section>
</template>
