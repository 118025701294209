<script setup lang="ts"></script>

<template>
  <div class="slanted-container container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl pl-5 2xl:px-0 mt-48 sm:mt-2 relative z-20 primary">
    <div class="md:px-14 lg:px-2 ml-2 line-1">
      <h1 class="w-fit slanted sm:ml-4 px-4 sm:px-4">
        <slot name="line1"></slot>
      </h1>
    </div>
    <div class="ml-2 sm:ml-10 mt-1 mb-3 md:mb-10 md:px-14 lg:px-2 line-2">
      <h1 class="w-fit slanted sm:-ml-4 px-4 sm:pr-4">
        <slot name="line2"></slot>
      </h1>
    </div>
  </div>
</template>

<style scoped></style>
